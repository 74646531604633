import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@headlessui/react";
import { cn } from "../../../data/configs/utils";

const QuickReply = ({ inputLock, onClick }) => {
  const quickReplies = useSelector((state) => state.botDetails.quickReplies)
  const autoSuggestQuickReplies = useSelector((state) => state.botDetails.autoSuggestQuickReplies)
  const direction = useSelector((state) => state.themeDetails.direction)
  const trackRef = useRef(null)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)
  const trackContainerRef = useRef(null)
  const [data, setData] = useState({
    translate: 0,
    disableLeft: false,
    disableRight: true,
  })

  useEffect(() => {
    const viewWidth = trackContainerRef.current.offsetWidth
    const trackWidth = trackRef.current.scrollWidth
    setData({
      translate: 0,
      disableLeft: direction === "rtl" ? true : viewWidth > trackWidth,
      disableRight: direction === "rtl" ? viewWidth > trackWidth : true,
    })
  }, [quickReplies.length, direction])

  const handleTrackScroll = (e) => {
    let leftScroll = trackContainerRef.current.scrollLeft
    if(direction === "rtl") {
      leftScroll = (trackContainerRef.current.scrollWidth - trackContainerRef.current.clientWidth) + trackContainerRef.current.scrollLeft
    }
    const rightScroll = trackContainerRef.current.scrollWidth - trackContainerRef.current.clientWidth - leftScroll
    leftArrowRef.current.style.visibility = leftScroll > 10 ? 'visible' : 'hidden'
    rightArrowRef.current.style.visibility = rightScroll > 10 ? 'visible' : 'hidden'
  }

  useEffect(() => {
    handleTrackScroll()
    if (trackContainerRef.current) {
      trackContainerRef.current.addEventListener('scroll', handleTrackScroll)
    }
    return () => {
      if (trackContainerRef.current) {
        trackContainerRef.current.removeEventListener('scroll', handleTrackScroll)
      }
    }
  }, [])

  const handleSlide = (direction) => {
    if (direction === 'left') {
      trackContainerRef.current.scrollBy({
        left: -200,
        behavior: 'smooth'
      })
    } else {
      trackContainerRef.current.scrollBy({
        left: 200,
        behavior: 'smooth'
      })
    }
  }


  if (quickReplies?.length > 0 || autoSuggestQuickReplies?.length > 0)
    return (
      <div className="px-2 pt-3 pb-1">
        <div className="relative w-full">
          <div
            className={cn('Animated FadeIn absolute z-10 left-0 border h-full aspect-square flex items-center justify-center hover:opacity-95 shadow-xl bg-background rounded-full p-2 cursor-pointer',
              (autoSuggestQuickReplies?.length + quickReplies.length) > 2 ? 'visible' : 'hidden'
            )}
            ref={leftArrowRef}
            onClick={handleSlide.bind(this, "left")} >
            <i class="ri-arrow-left-line"></i>
          </div>
          <div
            className={cn('Animated FadeIn absolute z-10 right-0 border h-full aspect-square flex items-center justify-center hover:opacity-95 shadow-xl bg-background rounded-full p-2 cursor-pointer',
              (autoSuggestQuickReplies?.length + quickReplies.length) > 2 ? 'visible' : 'hidden'
            )}
            ref={rightArrowRef}
            onClick={handleSlide.bind(this, "right")} >
            <i class="ri-arrow-right-line"></i>
          </div>
          <div
            ref={trackContainerRef}
            className="relative w-full overflow-x-scroll scroll-visibility no-scrollbar"
          >
            <div
              ref={trackRef}
              className="inline-flex flex-nowrap transition-transform duration-500 ease-in-out"
              style={{ transform: `translate3d(${data.translate}px, 0, 0)` }}
            >
              {autoSuggestQuickReplies.map((reply, index) => (
                <QuickReplyChipButton inputLock={inputLock} key={index} onClick={() => onClick(reply)}>
                  {reply}
                </QuickReplyChipButton>
              ))}
              {quickReplies?.map((reply, index) => (
                <QuickReplyChipButton inputLock={inputLock} key={index} onClick={() => onClick(reply)}>
                  {reply}
                </QuickReplyChipButton>
              ))}
            </div>
          </div>
        </div>
      </div>

    );

  return null
}

const QuickReplyChipButton = ({ onClick, children, inputLock }) => {
  return (
    <Button
      className="mr-2 whitespace-nowrap px-3 py-1 text-sm border rounded-xl shadow-inner hover:bg-primary hover:text-primaryContrast focus:outline-none focus-visible:ring-2 disabled:opacity-50"
      onClick={onClick}
      disabled={inputLock}
    >
      {children}
    </Button>
  );
}

QuickReply.propTypes = {
  onClick: PropTypes.func,
  inputLock: PropTypes.bool,
}

QuickReply.defaultProps = {
  inputLock: false,
}

export default QuickReply;