import PropTypes from "prop-types"

import { IMG_ERROR } from "../../../data/assets"

const ShowMessage = ({
  message,
  title,
  containerProps,
  avatarSx,
  children,
  ...restProps
}) => {
  return (
    <div
      className="flex flex-col items-center justify-center w-full h-full"
      {...containerProps}
    >
      <img src={IMG_ERROR} alt="Error" className="w-[120px] h-[120px] my-4 opacity-30" {...restProps} />
      {title && (
        <p className="text-center text-xl font-bold">
          {title}
        </p>
      )}
      {message && (
        <p className="font-thin mb-4">
          {message}
        </p>
      )}
      {children}
    </div>
  )
}

ShowMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  avtarSx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  containerProps: PropTypes.object,
}

ShowMessage.defaultProps = {
  title: "Error!!",
  message: "Something went wrong",
  avatarSx: {},
  containerProps: {},
  children: null,
}

export default ShowMessage
