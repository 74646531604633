import { useState, useEffect } from "react"

export default function useDelayUnmount(isMounted, delayTime) {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    let timeoutId = null
    if (isMounted && !shouldRender) {
      setShouldRender(true)
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime)
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [isMounted, delayTime, shouldRender])

  return shouldRender
}
