import { configureStore } from "@reduxjs/toolkit"
import logger from "redux-logger"

import themeReducer from "./themeSlice"
import botReducer from "./botSlice"

import botMiddleware from "./botSlice/middleware"

const store = configureStore({
  reducer: {
    themeDetails: themeReducer,
    botDetails: botReducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(botMiddleware)
    if (process.env.NODE_ENV !== "production")
      middleware = middleware.concat(logger)
    return middleware
  },
  devTools: process.env.NODE_ENV !== "production",
})

export default store
