import { useDispatch, useSelector } from "react-redux"
import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { updateBotState } from "../../../data/store/botSlice"

const GlobalSnackBar = () => {
  const dispatch = useDispatch()
  const snackbarProps = useSelector((state) => state.botDetails.snackbarProps)

  const handleCloseSnackBar = (event, reason) => {
    dispatch(
      updateBotState({
        snackbarProps: {
          open: false,
        },
      })
    )
  }

  return (
    <Snackbar
      sx={{ mt: 1, mr: 6, ml: 1 }}
      autoHideDuration={1500}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={
        <IconButton
          size="small"
          aria-label="snackbar-close"
          color="inherit"
          onClick={handleCloseSnackBar}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      onClose={handleCloseSnackBar}
      {...snackbarProps}
    />
  )
}

export default GlobalSnackBar
