import {useSelector} from "react-redux";
import {cn} from "../../../../data/configs/utils";

const OriAvatar = ({className, url, alt}) => {
    const botName = useSelector((state) => state.themeDetails.botName)

    const generateInitials = () => {
        const words = botName.trim().split(/\s+/)
        if(words.length === 0){
            return "B"
        }
        const firstInitial = words[0].charAt(0).toUpperCase();
        const lastInitial = words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : '';

        return firstInitial + lastInitial;
    }

    if(!url){
        return (
            <div className={
                cn(
                "h-full grid place-content-center text-6xl rounded-full bg-primary text-primaryContrast",
                    className,
                )
            }>
                {generateInitials()}
            </div>
        )
    }

    return (
        <img
            src={url}
            alt={alt}
            className={
                cn(
                    "h-full aspect-square rounded-full object-cover",
                    className
                )
            }/>
    )
}

export default OriAvatar