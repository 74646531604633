import * as _ from 'lodash';
import { useSelector } from "react-redux";

import { DEFAULT_LANGUAGE_MAP } from "../configs/constants";

export default function useLanguage(accessProperties) {
    const languageTempalate = useSelector((state) => state.themeDetails.languageTemplate) || DEFAULT_LANGUAGE_MAP

    return _.get(languageTempalate, accessProperties) || _.get(DEFAULT_LANGUAGE_MAP, accessProperties) || ""
    
}