import { createContext, useEffect, useState } from "react"
import { LOCAL_STORAGE, ROUTE_PATHS } from "../../../../data/configs/constants"
import { Outlet, useNavigate } from "react-router-dom"
import HomeNavbar from "./HomeNavbar"
import { getDataFromLocalStorage } from "../../../../data/configs/utils"


export const NavContext = createContext()
const navItems = [{
  icon: <i class="ri-home-fill"></i>,
  id: "home",
  path: ROUTE_PATHS.HOME
}, {
  icon: <i class="ri-chat-1-fill"></i>,
  id: "myChat",
  path: ROUTE_PATHS.CHAT_HISTORY
}, {
  icon: <i class="ri-question-fill"></i>,
  id: "resources",
  path: ROUTE_PATHS.RESOURCES
}]

const ChatHome = () => {
  const navigate = useNavigate()
  const [navState, setNavState] = useState(false)

  useEffect(() => {
    const currentSessionId = getDataFromLocalStorage(LOCAL_STORAGE.CURRENT_SESSION_ID)
    if (currentSessionId) {
      navigate(ROUTE_PATHS.CHAT_WITH_SESSIONID.replace(':sessionId', currentSessionId))
    }
  }, [navigate])

  return (
    <NavContext.Provider value={{ navState, setNavState }}>
      <div className='relative h-full w-full flex bg-primary bg-gradient-to-t from-background to-transparent '>
        <div className='h-full w-full overflow-auto'>
          <Outlet />
        </div>
        <div className='absolute bottom-0 left-0 right-0 flex justify-center bg-transparent p-4'>
          <HomeNavbar options={navItems} />
        </div>
      </div>
    </NavContext.Provider>
  )
}

export default ChatHome