import React from 'react'
import { cn } from '../../../../data/configs/utils'

const OriSkeleton = ({className, ...restProps}) => {
  return (
    <div className={cn('bg-gray-300 animate-pulse',className)} {...restProps}>

    </div>
  )
}

export default OriSkeleton