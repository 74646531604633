import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import "./index.scss"
import "./output.css"

import store from "./data/store"
import reportWebVitals from "./reportWebVitals"
import * as Sentry from "@sentry/react";

import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("saas-chatbot"))

if(process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    release: process.env.REACT_APP_VERSION,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
