import { cn, getUrlParams } from '../../../data/configs/utils'
import { BOT_POSITION } from '../../../data/configs/constants'

const PARAMS = getUrlParams()

const ChatTrigger = ({
    triggerSize,
    triggerShape,
    triggerUrl,
    botPosition,
    isBotOpen,
    handleTriggerClick,
}) => {
    return (
        <div onClick={handleTriggerClick} className={cn(`fixed right-[10px] bottom-[10px] bg-primary text-primaryContrast p-2 flex justify-center items-center shadow-md overflow-hidden h-[50px] w-[50px]`,
            triggerSize && `h-[${triggerSize}] w-[${triggerSize}]`,
            botPosition === BOT_POSITION.RIGHT ? "right-[10px]" : "left-[10px]",
            triggerShape === "cornered" ? "rounded-full rounded-br-none" : triggerShape === "rounded" ? "rounded-xl" : "rounded-full",
            triggerShape === "cornered" && botPosition === BOT_POSITION.LEFT && 'rounded-full rounded-bl-none',
            "cursor-pointer hover:scale-110 transition-transform duration-500ms",
            PARAMS.customAvatar === "true" && !isBotOpen ? "hidden" : "flex",
            'active:scale-90'
        )}>
            {isBotOpen ? (
                <p className='text-2xl'>
                    <i class="ri-arrow-down-s-line"></i>
                </p>
            ) : triggerUrl ? (
                <img src={triggerUrl} alt="trigger" className="Animated ZoomIn h-full w-full scale-90 object-cover rounded-full" />
            ) : (
                <p className='Animated ZoomIn text-2xl'>
                    <i class="ri-chat-1-fill"></i>
                </p>
            )}
        </div>
    )
    // TODO: Remove after testing
    // return (
    //     <Box
    //         position="fixed"
    //         bottom="10px"
    //         p={1.5}
    //         display="flex"
    //         justifyContent="center"
    //         alignItems="center"
    //         boxShadow={2}
    //         overflow="hidden"
    //         backgroundColor="primary.main"
    //         color="primary.contrastText"
    //         height={triggerSize}
    //         width={triggerSize}
    //         sx={{
    //             [botPosition]: "10px",
    //             cursor: "pointer",
    //             display: PARAMS.customAvatar === "true" && !isBotOpen ? "none" : "flex",
    //             "&:hover": {
    //                 transform: "scale(1.1)",
    //                 transitionDuration: "500ms",
    //             },
    //             ...(triggerShape === "cornered"
    //                 ? {
    //                     borderTopLeftRadius: theme.spacing(5),
    //                     borderTopRightRadius: theme.spacing(5),
    //                     borderBottomLeftRadius: theme.spacing(5),
    //                     borderBottomRightRadius: theme.spacing(0.5),
    //                 }
    //                 : triggerShape === "rounded"
    //                     ? { borderRadius: 2 }
    //                     : { borderRadius: "50%" }),
    //         }}
    //         onClick={handleTriggerClick}
    //     >
    //         {isBotOpen ? (
    //             <CloseIcon
    //                 className="Animated ZoomIn"
    //                 fontSize="large"
    //             />
    //         ) : triggerUrl ? (
    //             <Box
    //                 className="Animated ZoomIn"
    //                 component="img"
    //                 src={triggerUrl}
    //                 alt="trigger"
    //                 height="100%"
    //             />
    //         ) : (
    //             <InsertCommentIcon
    //                 className="Animated ZoomIn"
    //                 fontSize="medium"
    //             />
    //         )}
    //     </Box>
    // )
}

export default ChatTrigger