import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import {
  DEFAULT_THEME_VARIABLES,
  IFRAME_EVENT_TYPE,
} from "../../../data/configs/constants"
import { sendUpdateToParent } from "../../../data/configs/utils"
import {
  toggleBotInterface,
  addMessages,
  updateRouteBasedNotifications,
  updateBotState,
} from "../../../data/store/botSlice"

import Body from "../../components/Body"
import OriIconButton from "../../components/Custom/OriIconButton"

const Notifications = ({ parentPathName }) => {
  const dispatch = useDispatch()
  const notificationRef = useRef(null)
  const scrollTimer = useRef(false)
  const routeNotificationTimer = useRef(null)

  const unReadMessages = useSelector((state) => state.botDetails.unReadMessages)
  const routeBasedNotifications = useSelector(
    (state) => state.botDetails.routeBasedNotifications
  )

  useEffect(() => {
    return () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current)
    }
  }, [])

  useEffect(() => {
    if (notificationRef?.current)
      sendUpdateToParent(IFRAME_EVENT_TYPE.MINIMIZE_CHATBOT, {
        height: `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET} + ${notificationRef?.current?.scrollHeight}px + 32px)`,
        width: `calc(${DEFAULT_THEME_VARIABLES.NOTIFICATION_BODY_WIDTH} + 24px)`,
      })

    if (scrollTimer.current) clearTimeout(scrollTimer.current)
    if (notificationRef.current)
      scrollTimer.current = setTimeout(() => {
        notificationRef.current.scroll({
          top: notificationRef.current.scrollHeight,
          behavior: "smooth",
        })
      }, 750)
  }, [unReadMessages.length])

  useEffect(() => {
    let msgIntervalTimer = null
    if (
      parentPathName &&
      routeBasedNotifications[parentPathName] &&
      routeBasedNotifications[parentPathName].messages.length > 0 &&
      !routeBasedNotifications[parentPathName].freeze
    ) {
      routeNotificationTimer.current = setTimeout(() => {
        let index = 0
        msgIntervalTimer = setInterval(() => {
          dispatch(
            addMessages({
              messages: [
                {
                  ...routeBasedNotifications[parentPathName].messages[index],
                  timestamp: new Date().getTime(),
                },
              ],
            })
          )
          index = index + 1
          if (
            index === routeBasedNotifications[parentPathName].messages.length
          ) {
            dispatch(
              updateRouteBasedNotifications({
                pathname: parentPathName,
                changedValues: {
                  freeze: true,
                },
              })
            )
            clearInterval(msgIntervalTimer)
          }
        }, 1000)
      }, routeBasedNotifications[parentPathName].waitingTime * 1000)
    }

    return () => {
      if (msgIntervalTimer) clearInterval(msgIntervalTimer)
      if (routeNotificationTimer.current)
        clearTimeout(routeNotificationTimer.current)
    }
  }, [dispatch, parentPathName, routeBasedNotifications])

  const handleNotificationClick = () => {
    dispatch(toggleBotInterface())
  }

  const handleNotificationBotClose = () => {
    dispatch(updateBotState({ unReadMessages: [] }))
  }

  if (unReadMessages.length === 0) return null

  return (
    <div className="fixed bottom-[--trigger-size] mb-4 max-w-[400px] right-[8px] top-[16px] flex flex-col justify-end">
      <div className="flex justify-end mb-1">
        <OriIconButton
          aria-label="NotificationBotClose"
          className="rounded-full h-6"
          onClick={handleNotificationBotClose}
        >
          <i class="ri-close-large-line"></i>
        </OriIconButton>
      </div>
      <div
        ref={notificationRef}
        className="max-h-[calc(88vh - 100px + 20px)] overflow-auto cursor-pointer"
        onClick={handleNotificationClick}
      >
        <Body messages={unReadMessages} notification />
      </div>
    </div>
  )
}

Notifications.propTypes = {
  parentPathName: PropTypes.string,
}

Notifications.defaultProps = {
  parentPathName: "",
}

export default Notifications
