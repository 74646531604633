import React, { useContext } from 'react'
import { NavContext } from '.'
import { useNavigate } from 'react-router-dom'
import OriIconButton from '../../../components/Custom/OriIconButton'
import { cn } from '../../../../data/configs/utils'
import OriTooltip from '../../../components/Custom/OriTooltip'
import useLanguage from '../../../../data/hooks/useLanguage'

const HomeNavbar = ({ options = [] }) => {
  const navigate = useNavigate()
  const { navState, setNavState } = useContext(NavContext)
  const tooltipLanguageMap = useLanguage("botUiTemplate.tooltips")

  const handleItemClicked = (index, item) => {
    setNavState({
      selectedIndex: index,
    })
    navigate(item.path)
  }

  return (
    <div className='flex gap-3 border bg-background border-gray-100 shadow-2xl p-2 rounded-full'>
      {options.map((item, index) => (
        <OriTooltip label={tooltipLanguageMap[item?.id]}>
          <OriIconButton
            variant="text"
            key={index}
            onClick={() => handleItemClicked(index, item)}
            className={cn('p-4 h-10 w-10 text-2xl text-inherit rounded-full transition-all duration-500',
              (navState.selectedIndex || 0) === index ? 'bg-primary text-primaryContrast' : 'hover:bg-secondary hover:text-secondaryContrast'
            )}>
            {item.icon}
          </OriIconButton>
        </OriTooltip>
      ))}
    </div>
  )
}

export default HomeNavbar