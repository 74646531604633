import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import ChatBody from '../ChatBody.js'
import Footer from '../../../components/Footer'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getChatLogsForSessionId } from '../../../../data/store/botSlice/index.js'
import { removeDataFromLocalStorage, setDataInLocalStorage } from '../../../../data/configs/utils.js'
import { LOCAL_STORAGE } from '../../../../data/configs/constants.js'
import OriLoader from '../../../components/Custom/OriLoader/index.js'

const ChatWindow = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const tenantId = useSelector((state) => state.botDetails.tenantId)
    const [loadingChatLogs, setLoadingChatlogs] = useState(true)
    const hasChatEnded = useSelector((state) => state.botDetails.hasChatEnded)
    const sessionId = useSelector((state) => state.botDetails.sessionId)

    useEffect(()=>{
        if(!hasChatEnded && sessionId){
            setDataInLocalStorage(LOCAL_STORAGE.CURRENT_SESSION_ID, sessionId)
        } else {
            removeDataFromLocalStorage(LOCAL_STORAGE.CURRENT_SESSION_ID)
        }
    },[hasChatEnded, sessionId])


    useEffect(() => {
        if (params.sessionId) {
            dispatch(getChatLogsForSessionId({
                sessionId: params.sessionId,
                payload: {
                    tenantId
                }
            })).finally(() => {
                setLoadingChatlogs(false)
            })
        } else {
            setLoadingChatlogs(false)
        }
    }, [params, dispatch, tenantId])

    if (loadingChatLogs) {
        return <OriLoader/>
    }

    return (
        <div className='h-full flex flex-col'>
            <Header />
            <div className='flex-grow overflow-auto shadow-inner border-b-[1px]'>
                <ChatBody />
            </div>
            <Footer />
        </div>
    )
}

export default ChatWindow