import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  fetchDataAndProceed,
  // linkify,
  sendUpdateToParent,
} from "../../configs/utils"

import {
  END_CHAT,
  GET_BOT_CURRENT_STATE,
  MARK_FLAG_ON_MESSAGE,
  SEND_FEEDBACK,
  MESSAGE_UPVOTE_DOWNVOTE,
  CHAT_HISTORY,
  GET_CHAT_LOGS,
  // SEND_MESSAGE,
  // SUBMIT_FORM_MESSAGE,
} from "../../configs/apis"
import {
  METHOD_TYPES,
  IFRAME_EVENT_TYPE,
  DEFAULT_THEME_VARIABLES,
  // MESSAGE_TYPES,
  MESSAGE_SENDER, MESSAGE_TYPES,
} from "../../configs/constants"
import _ from 'lodash'

import initialState from "./initialState"

const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    updateBotState(state, action) {
      return { ...state, ...action.payload }
    },
    makeSocketConnection() {
      return
    },
    disconnectChatSocket() {
      return
    },
    sendMessage() {
      return
    },
    sendMessageStream() {
      return 
    },
    emitCustomEvent() {
      return
    },
    resetConversation (state, action) {
      return {
        ...state,
        messages: [
          ...state.welcomeMessages
        ],
        hasChatEnded: false
      }
    },
    updateMessage(state, action) {
      if (action.payload.id && action.payload.updatedValues) {
        const msgIndex = state.messages.findIndex(
          (msg) => msg.id === action.payload.id
        )
        if (msgIndex !== -1) {
          state.messages[msgIndex] = {
            ...state.messages[msgIndex],
            ...action.payload.updatedValues,
          }
        }
      }
    },
    addMessages(state, action) {
      if (action.payload?.messages?.length > 0) {
        const messages = state.messages;
        // NOTE: all the items in messages array will have same deliveredMsgId
        const deliveredMsgId = action.payload.messages[0]?.deliveredMsgId;
        const sender = action.payload.messages[0]?.sender;
        if(sender === MESSAGE_SENDER.USER) {
          state.messages = [
            ...state.messages,
            ...action.payload.messages
          ]
          return;
        }
        // deleting existing messages with same deliveredMsgId - this is to remove the streamed message
        var arrayAfterDeletion = messages.filter(
          (msg, index) => !msg?.isDeletable || msg?.deliveredMsgId !== deliveredMsgId || msg?.sender === MESSAGE_SENDER.USER || index === 0
        );
        // adding new messages below question with same deliveredMsgId for bot response
        const messageIndexWithSameDeliveredMessageId = arrayAfterDeletion.findIndex(
          (msg) => msg?.deliveredMsgId === deliveredMsgId
        )
        if(messageIndexWithSameDeliveredMessageId<=0) {
          state.messages = [
            ...arrayAfterDeletion,
            ...action.payload.messages
          ]
        } else {
          state.messages = [
            ...arrayAfterDeletion.slice(0, messageIndexWithSameDeliveredMessageId + 1),
            ...action.payload.messages,
            ...arrayAfterDeletion.slice(messageIndexWithSameDeliveredMessageId + 1)
          ]
        }
        // ----- handle QuickReplies -----
        const quickReplies = action.payload.quickReplies
        if (quickReplies?.length > 0) {
          state.autoSuggestQuickReplies = quickReplies
        }
        // ----- handle UnreadMessages -----
        if (!state.isBotOpen && !action.payload.skipUnread)
          state.unReadMessages.push(...action.payload.messages)
        if (
          !state.enableLiveChatButton &&
          state.messages.length - state.welcomeMessages.length >= 4
        )
          state.enableLiveChatButton = true
      }
    },
    addStreamMessage (state, action) {
      const receivedMessage = action.payload;
      const messages = state.messages;
      const existingBotResponseIndex = messages.findIndex(
        (msg) => msg.deliveredMsgId === receivedMessage.deliveredMsgId && msg.sender === MESSAGE_SENDER.CHATBOT
      )
      if(existingBotResponseIndex<0) {
        const questionIndex = messages.findIndex(
          (msg) => msg.deliveredMsgId === receivedMessage.deliveredMsgId && msg.sender === MESSAGE_SENDER.USER
        )
        const newMessage = {
          ...receivedMessage,
          sender: MESSAGE_SENDER.CHATBOT,
          status: 0,
          type: 'text',
          isDeletable: true,
          payload:{
            text: receivedMessage.text
          }
        }
        state.messages = [
          ...messages.slice(0,questionIndex+1),
          newMessage,
          ...messages.slice(questionIndex+1)
        ]
      } else {
        const newMessage = {
          ...messages[existingBotResponseIndex],
          payload: {
            text: messages[existingBotResponseIndex].payload.text + receivedMessage.text,
          }
        }
        state.messages = [
          ...messages.slice(0,existingBotResponseIndex),
          newMessage,
          ...messages.slice(existingBotResponseIndex+1),
        ]
      }
    },
    toggleBotInterface(state, action) {
      if (state.isBotOpen) {
        setTimeout(() => {
          const data = {
            height: `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET})`,
            width: `calc(${DEFAULT_THEME_VARIABLES.TRIGGER_SIZE} + ${DEFAULT_THEME_VARIABLES.TRIGGER_BOT_OFFSET})`,
          }
          sendUpdateToParent(IFRAME_EVENT_TYPE.MINIMIZE_CHATBOT, data)
        }, 300)
      } else {
        sendUpdateToParent(IFRAME_EVENT_TYPE.MAXIMIZE_CHATBOT, action.payload)
        state.snackbarProps = initialState.snackbarProps
      }
      state.isBotOpen = !state.isBotOpen
      if (state.isBotOpen && state.unReadMessages.length > 0) {
        state.firstUnreadMessageId = state.unReadMessages[0]?.id
        state.unReadMessages = []
      } else {
        state.firstUnreadMessageId = ""
      }
    },
    updateRouteBasedNotifications(state, action) {
      if (
        action.payload?.pathname &&
        state.routeBasedNotifications[action.payload.pathname]
      ) {
        state.routeBasedNotifications[action.payload.pathname] = {
          ...state.routeBasedNotifications[action.payload.pathname],
          ...action.payload.changedValues,
        }
      }
    },
    addNewRelayData(state, { payload }) {
      if (payload?.relayData) {
        var newRelayData = [];
        newRelayData = [payload];
        const newRelayPayload = {
          type: payload?.relayData?.type,
          subType: payload?.relayData?.subType,
          qty: newRelayData?.length,
          data: newRelayData?.map((data) => data?.relayData)
        }
        state.relay = {
          data: newRelayData,
          payload: newRelayPayload
        }
      }
    },
    removeRelayData(state, { payload }) {
      const deleteIndex = payload?.deleteIndex;
      if (deleteIndex > -1 && deleteIndex < state.relay.data.length) {
        const newRelayData = [
          ...state.relay.data.slice(0, deleteIndex),
          ...state.relay.data.slice(deleteIndex + 1)
        ];
        var newRelayPayload = {
          type: state.relay.payload.type,
          subType: state.relay.payload.subType,
          qty: newRelayData?.length,
          data: newRelayData?.map((data) => data?.relayData)
        }
        if (newRelayData.length === 0) {
          newRelayPayload = {}
        }
        state.relay = {
          data: newRelayData,
          payload: newRelayPayload
        }
      }
    },
    clearRelayData(state) {
      state.relay = initialState.relay
    },
    updateUserLoginData(state, {payload}) {
      state.userLoginData = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBotCurrentState.fulfilled, (state, { payload }) => {
        if (payload?.data?.chatStatus)
          state.chatStatus = payload.data.chatStatus
        if (payload?.data?.sessionId) state.sessionId = payload.data.sessionId
      })
      .addCase(markOrUnmarkFlagOnMessage.pending, (state, { meta }) => {
        const msgIndex = state.messages.findIndex(
          (msg) => msg.id === meta?.arg?.messageId
        )
        if (msgIndex !== -1) state.messages[msgIndex].markingFlag = true
      })
      .addCase(
        markOrUnmarkFlagOnMessage.fulfilled,
        (state, { meta, payload }) => {
          const msgIndex = state.messages.findIndex(
            (msg) => msg.id === meta?.arg?.messageId
          )
          if (
            msgIndex !== -1 &&
            state.messages[msgIndex].markingFlag !== undefined
          ) {
            delete state.messages[msgIndex].markingFlag
            state.messages[msgIndex].flag = payload.data.isTrainingRequired
          }
        }
      )
      .addCase(markOrUnmarkFlagOnMessage.rejected, (state, { meta }) => {
        const msgIndex = state.messages.findIndex(
          (msg) => msg.id === meta?.arg?.messageId
        )
        if (
          msgIndex !== -1 &&
          state.messages[msgIndex].markingFlag !== undefined
        )
          delete state.messages[msgIndex].markingFlag
      })
      .addCase(messageUpvoteDownvote.fulfilled, (state, { payload }) => {
        const msgIndex = state.messages.findIndex(
          (msg) => msg.id === payload?.data?.messageId
        )
        if (msgIndex > -1)
          state.messages[msgIndex].customerVote = payload.data.customerVote
      })
      .addCase(endChat.pending, (state) => {
        state.modalLoading = true
      })
      .addCase(endChat.fulfilled, (state) => {
        state.modalLoading = false
        state.startNewSession = true
        state.sessionId = null
        state.messages = state.welcomeMessages
        state.firstUnreadMessageId = ""
        state.unReadMessages = []
        // state.quickReplies = []
      })
      .addCase(endChat.rejected, (state) => {
        state.modalLoading = false
      })
      .addCase(sendFeedback.pending, (state) => {
        state.modalLoading = true
      })
      .addCase(sendFeedback.fulfilled, (state) => {
        state.modalLoading = false
      })
      .addCase(sendFeedback.rejected, (state) => {
        state.modalLoading = false
      })
      .addCase(getChatHistory.fulfilled, (state, { payload, meta }) => {
        const page = meta.arg.page
        const limit = meta.arg.limit
        if(limit === 1) state.recentChat = _.get(payload, 'data.chatSessions[0]', null)
        else if (page === 1) {
          state.chatHistoryData = payload.data
        }
        else {
          state.chatHistoryData = {
            ...state.chatHistoryData,
            chatSessions: [
              ..._.get(state,'chatHistoryData.chatSessions',[]),
              ...payload.data.chatSessions
            ]
          }
        }
      })
      .addCase(getChatLogsForSessionId.fulfilled, (state, { payload }) => {
        state.messages = [
          ...state.welcomeMessages,
          ...payload.data.chatLogs,
        ]
        state.startNewSession = false
      })
  },
})

export const getBotCurrentState = createAsyncThunk(
  "bot/getBotCurrentState",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: GET_BOT_CURRENT_STATE.replace(":psid", data.psid),
      data,
    })
    return response.data
  }
)

export const markOrUnmarkFlagOnMessage = createAsyncThunk(
  "bot/markOrUnmarkFlagOnMessage",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: MARK_FLAG_ON_MESSAGE,
      method: METHOD_TYPES.POST,
      data,
    })
    return response.data
  }
)

export const messageUpvoteDownvote = createAsyncThunk(
  "bot/messageUpvoteDownvote",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: MESSAGE_UPVOTE_DOWNVOTE,
      method: METHOD_TYPES.POST,
      data,
    })
    return response.data
  }
)

export const endChat = createAsyncThunk("bot/endChat", async (data) => {
  const response = await fetchDataAndProceed({
    url: END_CHAT.replace(":sessionId", data.sessionId),
    method: METHOD_TYPES.POST,
    data,
  })
  return response.data
})

export const sendFeedback = createAsyncThunk(
  "bot/sendFeedback",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: SEND_FEEDBACK.replace(":sessionId", data.sessionId),
      method: METHOD_TYPES.POST,
      data,
    })
    return response.data
  }
)

export const getChatHistory = createAsyncThunk(
  "bot/getChatHistory",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: CHAT_HISTORY,
      method: METHOD_TYPES.POST,
      data,
    })
    return response.data
  }
)

export const getChatLogsForSessionId = createAsyncThunk(
  "bot/getChatLogsForSessionId",
  async (data) => {
    const response = await fetchDataAndProceed({
      url: GET_CHAT_LOGS.replace(":sessionId", data.sessionId),
      method: METHOD_TYPES.POST,
      data: data.payload,
    })
    return response.data
  }
)

export const {
  updateBotState,
  makeSocketConnection,
  disconnectChatSocket,
  updateMessage,
  sendMessage,
  sendMessageStream,
  emitCustomEvent,
  toggleBotInterface,
  addMessages,
  addStreamMessage,
  updateRouteBasedNotifications,
  addNewRelayData,
  removeRelayData,
  updateUserLoginData,
  clearRelayData,
  resetConversation
} = botSlice.actions
export default botSlice.reducer
