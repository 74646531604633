import React from 'react';
import { Button as HeadlessButton } from '@headlessui/react';
import { Loader } from 'lucide-react';
import { cn } from '../../../../data/configs/utils';

const sizeClasses = {
  small: 'px-2 py-1 text-sm',
  medium: 'px-4 py-2 text-base',
  large: 'px-6 py-3 text-lg',
};

const variantClasses = {
  text: 'bg-transparent',
  outlined: 'bg-transparent border',
  filled: '',
};

const colorClasses = {
  primary: {
    text: 'text-primary hover:opacity-80',
    outlined: 'text-primary border-primary hover:opacity-80',
    filled: 'bg-primary text-primaryContrast hover:opacity-80',
  },
  secondary: {
    text: 'text-secondaryContrast hover:opacity-80',
    outlined: 'text-secondaryContrast border-gray-600 hover:opacity-80',
    filled: 'bg-secondary text-secondaryContrast hover:opacity-80',
  },
  success: {
    text: 'text-green-600 hover:bg-green-100',
    outlined: 'text-green-600 border-green-600 hover:bg-green-100',
    filled: 'bg-green-600 text-white hover:bg-green-700',
  },
  error: {
    text: 'text-red-600 hover:bg-red-100',
    outlined: 'text-red-600 border-red-600 hover:bg-red-100',
    filled: 'bg-red-600 text-white hover:bg-red-700',
  },
  warning: {
    text: 'text-yellow-600 hover:bg-yellow-100',
    outlined: 'text-yellow-600 border-yellow-600 hover:bg-yellow-100',
    filled: 'bg-yellow-600 text-white hover:bg-yellow-700',
  },
};

const OriButton = ({
  size = 'medium',
  variant = 'filled',
  color = 'primary',
  icon,
  loading = false,
  disabled = false,
  children,
  className = '',
  ...props
}) => {
  const Icon = icon;
  
const classes = [
    sizeClasses[size],
    variantClasses[variant],
    colorClasses[color][variant],
    disabled ? 'opacity-50 cursor-not-allowed' : '',
    'inline-flex items-center justify-center rounded-md font-medium focus:outline-none transition-colors transition-all duration-500',
    className,
];

  if(!props?.href) return (
    <HeadlessButton
      disabled={disabled || loading}
      className={cn(...classes)}
      {...props}
    >
      {loading ? (
        <Loader className="animate-spin mr-2" size={size === 'small' ? 16 : size === 'large' ? 24 : 20} />
      ) : icon ? (
        <Icon className="mr-2" size={size === 'small' ? 16 : size === 'large' ? 24 : 20} />
      ) : null}
      {children}
    </HeadlessButton>
  )
  else return (
    <a
      disabled={disabled || loading}
      className={cn("no-link-format", ...classes)}
      {...props}
      rel="noopener noreferrer"
    >
      {loading ? (
        <Loader className="animate-spin mr-2" size={size === 'small' ? 16 : size === 'large' ? 24 : 20} />
      ) : icon ? (
        <Icon className="mr-2" size={size === 'small' ? 16 : size === 'large' ? 24 : 20} />
      ) : null}
      {children}
    </a>
  )
};

export default OriButton;