import dayjs from "dayjs"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { endChat, resetConversation, updateBotState } from "../store/botSlice"
import { ROUTE_PATHS } from "../configs/constants"
import _ from 'lodash'
import { PLATFORM } from "../configs/urls"
import { useCallback } from "react"

const useChatbotActions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const psid = useSelector((state) => state.botDetails.psid)
    const tenantId = useSelector((state) => state.botDetails.tenantId)
    const sessionId = useSelector((state) => state.botDetails.sessionId)

    const endChatSessionOnBE = () => {
        if(_.isEmpty(sessionId)) return Promise.resolve()
        const payload = {
            psid,
            tenantId,
            sessionId,
            platform: PLATFORM,
        }
        const promise = new Promise((resolve, reject) => {
            dispatch(endChat(payload)).unwrap().then(resolve).catch(reject)
        })
        return promise
    }

    const isChatActive = useCallback((chatMetaData, isLastChat = true) => {
        if(!chatMetaData) return false
        const minutesPassed = dayjs().diff(dayjs(chatMetaData?.lastBotMessageTimeStamp), 'minutes')
        const hasEnded = chatMetaData?.sessionStatus?.hasEnded
        if (hasEnded === true || minutesPassed > 30 || !isLastChat) {
            return false
        } else {
            return true
        }
    },[])

    const handleChatClicked = (chatMetaData, isLatest = true) => {
        dispatch(resetConversation())
        if (!isChatActive(chatMetaData, isLatest)) {
            dispatch(updateBotState({ hasChatEnded: true }))
        } else {
            dispatch(updateBotState({ hasChatEnded: false }))
        }
        navigate(ROUTE_PATHS.CHAT_WITH_SESSIONID.replace(':sessionId', chatMetaData.sessionId))
    }

    const handleStartNewConversation = () => {
        // TODO: Once we allow multiple sessions in parallel, we need to remove this
        endChatSessionOnBE().then(() => {
            navigate(ROUTE_PATHS.CHAT)
        })
    }


    return {
        handleChatClicked,
        handleStartNewConversation,
        isChatActive
    }

}

export default useChatbotActions;