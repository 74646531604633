import { io } from "socket.io-client"

import {
  updateBotState,
  makeSocketConnection,
  disconnectChatSocket,
  sendMessage,
  addMessages,
  emitCustomEvent,
  sendMessageStream,
  addStreamMessage,
  updateUserLoginData,
  removeRelayData,
} from "../botSlice"
import { PLATFORM, SOCKET_URL } from "../../configs/urls"
import { LOCAL_STORAGE, MESSAGE_SENDER, RELAY_DATA_SUBTYPES, RELAY_DATA_TYPES, SOCKET_EVENTS } from "../../configs/constants"
import { getUrlParams, isEmptyObject, sendUpdateToParent } from "../../configs/utils"
import * as _ from "lodash"

const middleware = (store) => {
  let socket = null
  return (next) => (action) => {
    if (makeSocketConnection.match(action)) {
      const botDetails = store.getState().botDetails
      const themeDetails = store.getState().themeDetails
      if (!botDetails.socketRequestProcessing) {
        store.dispatch(updateBotState({ socketRequestProcessing: true }))
        if (socket !== null) socket.close()
        const PARAMS = getUrlParams()
        const socketUrl = `${SOCKET_URL}/${botDetails.tenantId}`
        socket = io(socketUrl, {
          transports: ["websocket"],
          path: process.env.REACT_APP_BASE_SOCKET_PATH,
          query: {
            tenantId: botDetails.tenantId,
            psid: botDetails.psid,
            platformName: PLATFORM,
            parentUrl: PARAMS.parentUrl,
            localTime: new Date().toLocaleTimeString(),
            globalLanguage: themeDetails.language,
            'x-served-for': window.location.hostname,
            ...themeDetails.deviceData,
          },
        })

        socket.on(SOCKET_EVENTS.CONNECT, () => {
          store.dispatch(
            updateBotState({
              isSocketConnected: socket.connected,
              socketRequestProcessing: false,
            })
          )
        })

        socket.on(SOCKET_EVENTS.CONNECT_ERROR, () => {
          store.dispatch(
            updateBotState({
              isSocketConnected: socket.connected,
              socketRequestProcessing: false,
            })
          )
        })

        socket.on(SOCKET_EVENTS.ERROR, () => {
          store.dispatch(
            updateBotState({ isSocketConnected: socket.connected })
          )
        })

        socket.on(SOCKET_EVENTS.DISCONNECT, (reason) => {
          if (reason === "io server disconnect") socket.connect()
          store.dispatch(
            updateBotState({ isSocketConnected: socket.connected })
          )
        })

        socket.on(SOCKET_EVENTS.RESPONSE, (data) => {
          // checking if we got an error for quota exceeded and subscription expiry
          if(data?.messages?.[0]?.failureDetails?.code === "E-CH-OM-002"){
            sendUpdateToParent("botQuotaExpired", { messages: data.messages })
          } else if (data?.messages?.[0]?.failureDetails?.code === "E-CH-OM-001") {
            sendUpdateToParent("subExpired", { messages: data.messages })
          }
          const psid = store.getState().botDetails.psid
          if (data?.psid && data.psid === psid && data?.messages?.length > 0) {
            store.dispatch(addMessages(data))
            if (data.sessionId)
              store.dispatch(updateBotState({ sessionId: data.sessionId }))
            if (data?.knowledgeSource && data?.knowledgeSource?.chatLogId) {
              store.dispatch(
                updateBotState({
                  knowledgeSource: {
                    ...data.knowledgeSource,
                    chatLogId: data.messages[0]?.chatLogId,
                    messages: data.messages,
                  },
                })
              )
            } else {
              store.dispatch(
                updateBotState({
                  knowledgeSource: {},
                })
              )
            }
          }
        })

        socket.on(SOCKET_EVENTS.RESPONSE_STREAM, (data) => {
          const psid = store.getState().botDetails.psid
          if (data?.psid && data.psid === psid) {
            if (data && data?.deliveredMsgId) {
              store.dispatch(addStreamMessage(data));
            }
          }
        })

        socket.on(SOCKET_EVENTS.UPDATE, (data) => {
          if (data.chatStatus !== undefined)
            store.dispatch(updateBotState({ chatStatus: data.chatStatus }))
          if (data.sessionId !== undefined)
            store.dispatch(updateBotState({ sessionId: data.sessionId }))
        })

        socket.on(SOCKET_EVENTS.TYPING_STATUS, (payload) => {
          const psid = store.getState().botDetails.psid
          if (payload?.typingInfo?.senderPsid !== psid) {
            store.dispatch(
              updateBotState({ typingInfo: payload.typingInfo || {} })
            )
          }
        })
      }
    } else if (
      socket &&
      emitCustomEvent.match(action) &&
      action.payload.event
    ) {
      socket.emit(action.payload.event, action.payload.data)
    } else if (socket && sendMessage.match(action)) {
      // adding relay data to message payload
      const messages = _.get(store.getState(), "botDetails.messages")
      const lastMessage = messages[messages.length - 1];
      const lastMessageRelayData = lastMessage?.payload?.relayData;
      const sendLastMessageRelayData = (
        lastMessage?.sender !== MESSAGE_SENDER.USER 
        && lastMessageRelayData?.type === RELAY_DATA_TYPES.E_COM_SELECT
        && lastMessageRelayData?.subtype === RELAY_DATA_SUBTYPES.DATA
        );
      const userLoginData = _.get(store.getState(), "botDetails.userLoginData")
      if(!isEmptyObject(userLoginData)) {
        action.payload.metadata.userLoginAttributes = userLoginData;
        store.dispatch(updateUserLoginData({}))
        localStorage.removeItem(LOCAL_STORAGE.LOGIN_ATTRIBUTES)
      }
      if (sendLastMessageRelayData) {
        const relayData = _.get(lastMessage, "payload.relayData")
        if (relayData) {
          action.payload = {
            ...action.payload,
            message: {
              ...action.payload.message,
              payload: {
                ...action.payload.message.payload,
                relayData: relayData,
              },
            },
          }
        }
      } else {
        const relayData = _.get(store.getState(), "botDetails.relay.payload")
        if (!_.isEmpty(relayData)) {
          action.payload = {
            ...action.payload,
            message: {
              ...action.payload.message,
              payload: {
                ...action.payload.message.payload,
                relayData: relayData,
              },
            },
          }
        }
      }

      socket.emit(SOCKET_EVENTS.NEW_MESSAGE, action.payload, (err, res) => {
        const messages = store.getState().botDetails.messages
        const msgIndex = messages.findIndex(
          (msg) => msg.id === action.payload?.message?.id
        )
        if (!err && res?.message?.id) {
          store.dispatch(
            updateBotState({
              startNewSession: false,
              messages: [
                ...messages.slice(0, msgIndex),
                res.message,
                ...messages.slice(msgIndex + 1),
              ],
            })
          )
        } else {
          store.dispatch(
            updateBotState({
              messages: [
                ...messages.slice(0, msgIndex),
                { ...messages[msgIndex], status: "failed" },
                ...messages.slice(msgIndex + 1),
              ],
            })
          )
        }
        store.dispatch(removeRelayData({ deleteIndex: 0 }))
      })
    } else if(socket && sendMessageStream.match(action)){
      const messages = _.get(store.getState(), "botDetails.messages")
      const lastMessage = messages[messages.length - 1];
      const lastMessageRelayData = lastMessage?.payload?.relayData;
      const sendLastMessageRelayData = (
        lastMessage?.sender !== MESSAGE_SENDER.USER
        && lastMessageRelayData?.type === RELAY_DATA_TYPES.E_COM_SELECT
        && lastMessageRelayData?.subtype === RELAY_DATA_SUBTYPES.DATA
      );
      const userLoginData = _.get(store.getState(), "botDetails.userLoginData")
      if(!isEmptyObject(userLoginData)) {
        action.payload.metadata.userLoginAttributes = userLoginData;
        store.dispatch(updateUserLoginData({}))
        localStorage.removeItem(LOCAL_STORAGE.LOGIN_ATTRIBUTES)
      }
      if (sendLastMessageRelayData) {
        const relayData = _.get(lastMessage, "payload.relayData")
        if (relayData) {
          action.payload = {
            ...action.payload,
            message: {
              ...action.payload.message,
              payload: {
                ...action.payload.message.payload,
                relayData: relayData,
              },
            },
          }
        }
      } else {
        const relayData = _.get(store.getState(), "botDetails.relay.payload")
        if (!_.isEmpty(relayData)) {
          action.payload = {
            ...action.payload,
            message: {
              ...action.payload.message,
              payload: {
                ...action.payload.message.payload,
                relayData: relayData,
              },
            },
          }
        }
      }
      socket.emit(SOCKET_EVENTS.NEW_MESSAGE_STREAM, action.payload, (err, res)=> {
        const messages = store.getState().botDetails.messages
        const msgIndex = messages.findIndex(
          (msg) => msg.id === action.payload?.message?.id
        )
        if (!err && res?.message?.id) {
          store.dispatch(
            updateBotState({
              startNewSession: false,
              messages: [
                ...messages.slice(0, msgIndex),
                res.message,
                ...messages.slice(msgIndex + 1),
              ],
            })
          )
        } else {
          store.dispatch(
            updateBotState({
              messages: [
                ...messages.slice(0, msgIndex),
                { ...messages[msgIndex], status: "failed" },
                ...messages.slice(msgIndex + 1),
              ],
            })
          )
        }
        store.dispatch(removeRelayData({ deleteIndex: 0 }))
      })
    } else if (socket && disconnectChatSocket.match(action)) {
      socket.disconnect()
      socket = null
    }

    next(action)
  }
}

export default middleware
