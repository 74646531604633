import React, { useRef, useEffect, useCallback } from 'react';
import { cn } from '../../../../data/configs/utils';

const OriTextArea = ({
  maxRows = 10,
  value = "",
  onChange = () => { },
  className = "",
  autofocus = false,
  ...props
}) => {
  const textareaRef = useRef(null);

  const updateRows = useCallback(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;

      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);

      textarea.style.height = 'auto';
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = lineHeight * maxRows
      
      if(scrollHeight > maxHeight) textarea.style.height = `${maxHeight + 2}px`
      else textarea.style.height = `${scrollHeight + 2}px`;
    }
  }, [maxRows]);

  useEffect(() => {
    updateRows();
    window.addEventListener('resize', updateRows);
    return () => window.removeEventListener('resize', updateRows);
  }, [updateRows, value]);

  useEffect(() => {
    if (autofocus) {
      textareaRef.current.focus();
    }
  },[autofocus])

  const handleInput = () => {
    updateRows();
  };

  return (
    <>
      <textarea
        ref={textareaRef}
        rows={1}
        onInput={handleInput}
        onChange={onChange}
        value={value}
        className={cn(`bg-background`, className)}
        {...props}
      />
    </>
  );
};

export default OriTextArea;