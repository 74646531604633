import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { clearRelayData } from '../../../data/store/botSlice';
import OriIconButton from '../Custom/OriIconButton';


const ShowRelayData = () => {
    const dispatch = useDispatch();
    const relayData = useSelector((state) => _.get(state, 'botDetails.relay.data', []));
    // const [openedItem, setOpenedItem] = React.useState({ index: null, data: null });

    // const handleItemClicked = (index, data) => {
    //     if (openedItem.index === index) {
    //         setOpenedItem({ index: null, data: null });
    //         return;
    //     }
    //     setOpenedItem({ index, data });
    // };

    // const handleRemoveRelayData = (index) => {
    //     dispatch(removeRelayData({ deleteIndex: index }));
    //     setOpenedItem({ index: null, data: null });
    // };

    const handleClearRelayData = () => {
        dispatch(clearRelayData());
    }

    if (relayData.length === 0) return null;

    return (
        <div className='FadeIn flex gap-4 items-center p-2 rounded-xl shadow-inner w-full relative'>
            <img src={relayData[0].image} alt='' className='h-[64px] w-[64px] object-contain FadeIn' />
            {/* <ImageStack images={relayData.map(data => data.image)} /> */}
            <div flexGrow={1}>
                <p className='font-bold line-clamp-1 FadeIn'>{relayData?.[0]?.text}</p>
                <p className='line-clamp-1 FadeIn'>{relayData?.[0]?.subtitle}</p>
                <p className='text-xs FadeIn'> Know more about these products</p>
            </div>
            <OriIconButton
                variant='text'
                onClick={handleClearRelayData}
                className='h-4 text-backgroundContrast text-xs font-bold absolute top-2 right-2'>
                <i class="ri-close-large-line"></i>
            </OriIconButton>
            {/* TODO: Use this when we use more than one relay data */}
            {/* <div direction={'column'}>
                    {relayData.length > 1 && (
                        <Tooltip title="Compare" arrow>
                            <IconButton>
                                <i class="ri-pantone-line"></i>
                            </IconButton>
                        </Tooltip>
                    )}
                </div> */}
        </div>
    );
};

// const ImageStack = ({ images }) => {
//     return (
//         <Stack direction={'row-reverse'} spacing={1} position={'relative'} alignItems={'center'} height={64} width={64 + images.length * 5}>
//             {images.map((image, index) => {
//                 console.log("logging height", images.length * 4, 64 - (2 * (images.length - index)));
//                 return (
//                     <Box sx={{
//                         position: 'absolute',
//                         left: (images.length - index) * 5,
//                         right: 0,
//                         zIndex: images.length - index,
//                     }}>
//                         <Box sx={{ position: 'relative', width: 64, height: 64 }}>
//                             <Card variant='outlined' component={'img'} src={image} width={64} height={64 - 2 * index} key={index} sx={{
//                                 objectFit: 'scale-down',
//                             }} />
//                             {index === 0 && images.length > 1 && (
//                                 <Box sx={{
//                                     position: 'absolute',
//                                     right: 2,
//                                     bottom: 2,
//                                     backgroundColor: 'primary.main',
//                                     color: 'primary.contrastText',
//                                     borderRadius: '50%',
//                                     fontSize: 10,
//                                     width: 24,
//                                     height: 24,
//                                     display: 'flex',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                 }}>
//                                     {images.length}
//                                 </Box>
//                             )}
//                         </Box>
//                     </Box>
//                 )
//             })}
//         </Stack>
//     )
// }

// const RelayDataDetails = ({ item, onRemove }) => (
//     <Box component={Paper}
//         variant='outlined'
//         position={'relative'}
//         className='FadeIn'
//         padding={0.5}
//         sx={(theme) => ({
//             backgroundColor: lighten(theme.palette.primary.main, 0.8)
//         })}>
//         <Grid container justifyContent={'space-between'} alignItems={'center'}>
//             <Grid item xs={5} height={50}>
//                 <Box
//                     component={'img'}
//                     src={item?.data?.image}
//                     alt=''
//                     borderRadius={1}
//                     width={'auto'}
//                     height={'100%'}
//                     style={{
//                         width: 'auto',
//                         objectFit: 'cover',
//                         objectPosition: 'center'
//                     }} />
//             </Grid>
//             <Grid item xs={7}>
//                 <Tooltip title={item?.data?.text}>
//                     <Typography noWrap>{item?.data?.text}</Typography>
//                 </Tooltip>
//             </Grid>
//         </Grid>
//         <IconButton size='small' onClick={() => onRemove(item?.index)} sx={(theme) => ({
//             position: 'absolute',
//             right: -5,
//             top: -15,
//             backgroundColor: 'primary.main',
//             '&:hover': {
//                 backgroundColor: darken(theme.palette.primary.main, 0.2)
//             }
//         })}>
//             <Delete color='secondary' fontSize='inherit' />
//         </IconButton>
//     </Box>
// );

export default ShowRelayData;
