import React from 'react'
import { cn } from '../../../../data/configs/utils'

const OriLoader = ({className}) => {
    return (
        <div className={cn('h-full w-full flex items-center justify-center text-3xl animate-spin', className)}>
            <i class="ri-loader-2-line"></i>
        </div>
    )
}

export default OriLoader