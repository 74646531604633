import React from 'react';
import { sendUpdateToParent } from '../../../data/configs/utils';
import { IFRAME_EVENT_TYPE } from '../../../data/configs/constants';
import OriButton from '../Custom/OriButton';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import OriIconButton from '../Custom/OriIconButton';

const TrainAndTest = () => {
    const [open, setOpen] = React.useState(false);

    const handleMenuOpen = () => {
        setOpen(!open);
    }

    return (
        <Menu>
            <MenuButton>
                <button
                    aria-label="file-upload-icon"
                    aria-haspopup="true"
                    className="h-12 rounded-full bg-primary text-lg [--anchor-gap:8px] aspect-square text-primaryContrast duration-500 active:scale-90"
                    onClick={handleMenuOpen}>
                    <OriIconButton
                        className="text-2xl h-8">
                        <i class="ri-more-2-fill"></i>
                    </OriIconButton>
                </button>
            </MenuButton>
            <MenuItems
                transition
                anchor="bottom end"
                className={'bg-background text-backgroundContrast p-2 origin-top-right rounded-xl border transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'}>
                <MenuItem className="block data-[focus]:bg-primary data-[focus]:text-primaryContrast" onClick={() => {
                    sendUpdateToParent(IFRAME_EVENT_TYPE.OPEN_TENANT_TRAINING_HISTORY, {})
                }}>
                    <button className="flex w-full p-2 lg gap-2 rounded-md">
                        <i class="ri-history-fill"></i>
                        <p>Training History</p>
                    </button>
                </MenuItem>
                <MenuItem className="block data-[focus]:bg-primary data-[focus]:text-primaryContrast" onClick={() => {
                    window.location.reload()
                }}>
                    <button className="flex w-full p-2 lg gap-2 rounded-md">
                        <i class="ri-refresh-line"></i>
                        <p>Refresh</p>
                    </button>
                </MenuItem>
            </MenuItems>
        </Menu>
    )
    // TODO: Remove commented code after testing new ui
    // return (
    //     <div className="flex flex-row items-center bg-primary text-primary-contrast justify-end">
    //         <OriButton size='small' onClick={() => {
    //             sendUpdateToParent(IFRAME_EVENT_TYPE.OPEN_TENANT_TRAINING_HISTORY, {})
    //         }}>
    //             <i class="ri-history-fill"></i><span className='ml-2'>History</span>
    //         </OriButton>
    //         <OriButton size='small' onClick={() => {
    //             window.location.reload()
    //         }}>
    //             <i class="ri-refresh-line"></i><span className='ml-2'>Refresh</span>
    //         </OriButton>
    //     </div>
    // )
}

export default TrainAndTest;
