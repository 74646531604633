import React from 'react'
import useLanguage from '../../../../../data/hooks/useLanguage'

const ResourcesPage = () => {
  const resourcePageTitle = useLanguage("botUiTemplate.pages.resources.title")
  const comingSoon = useLanguage("botUiTemplate.pages.resources.commingSoon")
  return (
    <div className='h-full w-full flex flex-col gap-4 items-center justify-center'>
      <p className='text-6xl text-primaryContrast'>
        <i class="ri-hammer-line"></i>
      </p>
      <p className='text-2xl font-bold text-primaryContrast'>{resourcePageTitle}</p>
      <p className='text-sm text-primaryContrast'><i class="ri-time-line"></i> {comingSoon}</p>
    </div>
  )
}

export default ResourcesPage