const JumpingDots = () => {
  return (
    <div className="flex gap-1 py-1">
      <div
        className="animate-pulse h-1 w-1 rounded-full bg-primary"
      />
       <div
        className="animate-pulse  h-1 w-1 rounded-full bg-primary"
        style={{
          animationDelay: "0.2s",
        }}
      />
       <div
        className="animate-pulse delay-500 h-1 w-1 rounded-full bg-primary"
        style={{
          animationDelay: "0.4s",
        }}
      />
    </div>
  )
}

export default JumpingDots
