import React from 'react'
import { cn } from '../../../../data/configs/utils'

const OriTooltip = ({ className = "", label, children }) => {
    return (
        <div className='relative group'>
            {children}
            <div className={cn(
                'absolute FadeIn text-xs hidden group-hover:block bg-primary shadow-inner text-primaryContrast rounded-lg p-1 px-2 text-nowrap bottom-full -translate-y-1 left-1/2 -translate-x-[50%]',
                className
            )}>
                {label}
            </div>
        </div>
    )
}

export default OriTooltip