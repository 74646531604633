import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { cn, uniqueId } from "../../../data/configs/utils";
import { LOCAL_STORAGE, MESSAGE_SENDER, MESSAGE_TYPES } from "../../../data/configs/constants";
import { addMessages, addNewRelayData, sendMessage } from "../../../data/store/botSlice";
import { useDispatch } from "react-redux";
import { getClickableLink } from '../../../data/configs/utils';
import { useEffect, useRef } from 'react';
import OriIconButton from '../Custom/OriIconButton';
import OriButton from '../Custom/OriButton';

const CAROUSEL_WIDTH = 200;


const MessageCorouselData = function ({ carouselData = [] }) {
  const carouselTrackRef = useRef(null)
  const leftArrowRef = useRef(null)
  const rightArrowRef = useRef(null)

  const handleTrackScroll = (e) => {
    const leftScroll = carouselTrackRef.current.scrollLeft
    const rightScroll = carouselTrackRef.current.scrollWidth - carouselTrackRef.current.clientWidth - leftScroll
    leftArrowRef.current.style.visibility = leftScroll > 0 ? 'visible' : 'hidden'
    rightArrowRef.current.style.visibility = rightScroll > 0 ? 'visible' : 'hidden'
  }

  useEffect(() => {
    handleTrackScroll()
    if (carouselTrackRef.current) {
      carouselTrackRef.current.addEventListener('scroll', handleTrackScroll)
    }
    return () => {
      if (carouselTrackRef.current) {
        carouselTrackRef.current.removeEventListener('scroll', handleTrackScroll)
      }
    }
  }, [])

  const handleCarouselSlide = (direction) => {
    if (direction === 'left') {
      carouselTrackRef.current.scrollBy({
        left: -CAROUSEL_WIDTH,
        behavior: 'smooth'
      })
    } else {
      carouselTrackRef.current.scrollBy({
        left: CAROUSEL_WIDTH,
        behavior: 'smooth'
      })
    }
  }

  return <div className="relative scroll-visibility w-full px-2">
    <div
      className={cn('Animated FadeIn absolute z-10 top-1/2 left-4 border h-8 aspect-square flex items-center justify-center hover:opacity-95 shadow-xl bg-background rounded-full p-2 cursor-pointer',
        carouselData?.length > 2 ? 'visible' : 'hidden'
      )}
      ref={leftArrowRef}
      onClick={handleCarouselSlide.bind(this, "left")} >
      <i class="ri-arrow-left-line"></i>
    </div>
    <div
      className={cn('Animated FadeIn absolute z-10 top-1/2 right-4 border h-8 aspect-square flex items-center justify-center hover:opacity-95 shadow-xl bg-background rounded-full p-2 cursor-pointer',
        carouselData?.length > 2 ? 'visible' : 'hidden'
      )}
      ref={rightArrowRef}
      onClick={handleCarouselSlide.bind(this, "right")} >
      <i class="ri-arrow-right-line"></i>
    </div>
    <div ref={carouselTrackRef} className='flex items-stretch gap-4 overflow-scroll pb-2 w-full'>
      {carouselData?.map((corousel, index) => <div key={index}>
        <CorouselComponent corousel={corousel} />
      </div>)}
    </div>
  </div>
}

function CorouselComponent({ corousel }) {
  const dispatch = useDispatch()
  const handleButtonClick = (button) => {
    if (button?.href) {
      const link = getClickableLink(button.href);
      window.open(link, "_blank")
      return
    }
    if (button.text) {
      const message = {
        id: uniqueId(),
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.USER,
        payload: {
          text: button.text,
        },
        status: "pending",
        timestamp: new Date().getTime(),
      }
      dispatch(addNewRelayData(corousel))
      dispatch(addMessages({ messages: [message] }))
      dispatch(
        sendMessage({
          type: "message",
          info: {
            sender: MESSAGE_SENDER.USER,
            sessionId: sessionStorage.getItem(LOCAL_STORAGE.SESSION_ID), 
          },
          metadata: {
            button,
          },
          message,
        })
      )
    }
  }

  const handleShowSimilarClicked = () => {
    handleButtonClick({
      text: 'Show Similar Products',
    })
  }
  
  const handleKnowMoreClicked = () => {
    dispatch(addNewRelayData(corousel))
  }

  return <div className={`Animated SlideLeft rounded-2xl border h-full w-[200px] overflow-hidden flex flex-col shadow-md`}>
    <div className='relative'>
      <img src={corousel.image} alt={corousel.text} className={`relative w-full h-[200px] object-cover bg-gray-400`} />
      <div className={cn('flex flex-col gap-2 absolute right-2 bottom-2',
        corousel.type === "product" ? 'visible' : 'hidden'
      )}>
        <OriIconButton variant='outlined' onClick={handleKnowMoreClicked}
          className='h-8 text-lg bg-background text-primary shadow-xl'>
          <i class="ri-message-3-line"></i>
        </OriIconButton>
        <OriIconButton variant='outlined' onClick={handleShowSimilarClicked}
          className='h-8 text-lg bg-background text-primary shadow-xl'>
          <i class="ri-function-add-line"></i>
        </OriIconButton>
      </div>
    </div>
    <div className='flex-grow p-2'>
      <p className='font-bold line-clamp-1'>{corousel.text}</p>
      <p className='line-clamp-1'>{corousel.subtitle}</p>
    </div>
    <div className='p-2'>
      {corousel?.buttons?.length > 0 &&
        corousel.buttons.map((button, i) => {
          return (
            <OriButton
              size="small"
              variant="outlined"
              className='w-full min-w-[50px] mr-1 mt-1 rounded-3xl break-words'
              {...button?.props}
              onClick={() => handleButtonClick(button)}
            >
              {button?.text}
            </OriButton>
          )
        })}
    </div>
  </div>
}

export default MessageCorouselData
