import React from 'react';
import { cn } from '../../../../data/configs/utils';
import OriButton from '../OriButton';

const OriIconButton = ({
    children,
    className = '',
    ...restProps
}) => {
    return (
        <OriButton
            className={cn('p-0 rounded-full aspect-square',className)}
            {...restProps}
        >
            {children}
        </OriButton>
    );
};

export default OriIconButton;