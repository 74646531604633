import { useState } from "react"
import PropTypes from "prop-types"

import { getFormattedSize, openUrlInNewTab } from "../../../data/configs/utils"
import OriIconButton from "../Custom/OriIconButton"
import OriDialog from "../Custom/OriDialog"

const UploadedDocumentMessage = ({ payload }) => {
  const [showPreview, setShowPreview] = useState(false)

  const handleClosePreview = () => {
    setShowPreview(false)
  }

  const handleOpenPreview = () => {
    if (payload.fileUrl) setShowPreview(true)
  }

  const handleDownload = () => {
    if (payload.fileUrl)
      openUrlInNewTab(payload.fileUrl, "_blank")
  }

  return (
    <div className="flex gap-2">
      {payload.fileType.indexOf("image/") !== -1 ? (
        <img
          src={payload.fileUrl}
          alt="user-uploaded-img"
          className="h-16 aspect-square border rounded-xl object-cover cursor-pointer"
          onClick={handleOpenPreview}
        />
        // <Avatar
        //   src={payload.fileUrl}
        //   alt="user-uploaded-img"
        //   variant="rounded"
        //   sx={{ height: 70, width: 70, mr: 2, cursor: "pointer" }}
        // >
        //   <ImageIcon fontSize="large" />
        // </Avatar>
      ) : (
        <div onClick={handleDownload} className="cursor-pointer bg-gray-300 rounded-xl text-white h-16 aspect-square flex items-center justify-center">
          <p className="text-xl"><i class="ri-article-line"></i></p>
        </div>
        // <Avatar
        //   variant="rounded"
        //   sx={{ height: 70, width: 70, mr: 2, cursor: "pointer" }}
        //   onClick={handleDownload}
        // >
        //   <DescriptionIcon fontSize="large" />
        // </Avatar>
      )}
      <div className="flex flex-col justify-center">
        <p className="break-words font-semibold">
          {payload.fileName}
        </p>
        <div className="flex flex-wrap gap-1">
          {payload.pages && (
            <span className="text-xs">
              {`${payload.pages} ${payload.pages > 1 ? "Pages" : "Page"}`}
            </span>
          )}
          {payload.fileType && (
            <span className="text-xs uppercase">
              {payload.fileType}
            </span>
          )}
          {payload.fileSize && (
            <span className="text-xs">
              {getFormattedSize(payload.fileSize)}
            </span>
          )}
        </div>
      </div>
      <OriDialog open={showPreview} onClose={handleClosePreview}>
          <div className="relative rounded-xl border shadow-2xl p-2 bg-background">
            <OriIconButton
              className="h-6 absolute right-2 top-2 z-10"
              onClick={handleClosePreview}>
              <i class="ri-close-large-line"></i>
            </OriIconButton>
            <img alt="preview" src={payload.fileUrl} className="w-full aspect-square rounded-xl" />
          </div>
      </OriDialog>
      {/* <Dialog
        aria-labelledby="od-uploaded-image-preview"
        open={showPreview}
        maxWidth="md"
        onClose={handleClosePreview}
        fullWidth
      >
        <IconButton
          size="small"
          sx={{
            zIndex: 1,
            position: "absolute",
            right: 4,
            top: 4,
            backgroundColor: "background.paper",
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
          onClick={handleClosePreview}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            component="img"
            src={payload.fileUrl}
            alt="od-uploaded-image"
            width="100%"
          />
        </DialogContent>
      </Dialog> */}
    </div>
  )
}

UploadedDocumentMessage.propTypes = {
  payload: PropTypes.object.isRequired,
}

export default UploadedDocumentMessage
